.background {
  background: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url(../assets/images/Bakemonogatari-background.jpg); //fallback
  background-image: linear-gradient(
      40deg,
      rgba(13, 13, 13, 0) 0%,
      rgba(5, 5, 5, 0.46262254901960786) 0%
    ),
    url(../assets/images/Bakemonogatari-background.jpg);
  z-index: -1;
}
