.wrapper {
  position: absolute;
  top: 30vh;
  width: 90vw;
  padding: 0 0 2rem 0;
  z-index: 0;
  .home-text {
    color: #fff;
    .name {
      font-size: 3em;
    }

    .title {
      padding: 1.8rem 0;
      font-size: 2em;
      font-weight: 400;
    }
  }
}

.button-link {
  height: 50px;
  width: 200px;
  background-color: #796bae; // Fallback
  background-color: var(--primary);
  border-radius: 2px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: translateY(-0.08rem);
    background-color: #575795;
  }
}
