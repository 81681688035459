:root {
  /* PRIMARY */
  --primary: #796bae;
  --primary-gradient: #796baee6;

  /* LIGHT THEME COLORS */

  --light-element1: #000000;
  --light-element2: #111111;
  --light-element3: #222222;
  --light-surface1: #ffffff;
  --light-surface2: #e6e6e6;
  --light-surface3: #f2f2f2;

  /* DARK THEME COLORS */
  --dark-element1: #ffffff;
  --dark-element2: #eeeeee;
  --dark-element3: #dddddd;
  --dark-surface1: #000000;
  --dark-surface2: #262626;
  --dark-surface3: #333333;

  /* BACKGROUND GRADIENY */
  --linear-gradient: linear-gradient(
    40deg,
    rgba(42, 42, 47, 0.4962359943977591) 4%,
    rgba(0, 0, 0, 0.7315301120448179) 100%
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: YakuHanJP, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN',
    'Noto Sans JP', Meiryo, sans-serif;
}

html {
  width: 100%;
  height: 100%;
  display: table; // questionable
}

body {
  width: 100%;
  // min-height: 100%;
  display: table-cell; // questionable
}

li {
  list-style-type: none;
}

.home-container {
  padding: 0 5vw; //Important
}
