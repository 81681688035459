.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

// LIGHT & DARK THEME COLORS
.fullscreen-menu.darkTheme {
  background-color: #000; //Fallback var
  background-color: var(--dark-surface1);
}
.fullscreen-menu.lightTheme {
  background-color: #fff; //Fallback var
  background-color: var(--light-surface1);
}

.fullscreen-nav-ul {
  height: 100%;
  width: 100%;
  // LIGHT & DARK THEME COLORS
  .fullscreen-nav-li.darkTheme {
    //Fallback var
    border-bottom: 1px solid #fff; //Fallback var
    border-bottom: 1px solid var(--dark-element1);
  }
  .fullscreen-nav-li.lightTheme {
    border-bottom: 1px solid #000; //Fallback var
    border-bottom: 1px solid var(--light-element1);
  }
  .fullscreen-nav-li:last-child {
    border-bottom: none;
  }
}

.fullscreen-navlink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;

  .home-icon,
  .user-icon,
  .code-icon,
  .envelope-icon {
    height: 1.5em;
    width: 1.5em;
    font-size: 1.2rem;
    margin-right: 1rem;
    position: absolute;
    left: 15vw;
  }
}
// LIGHT & DARK THEME COLORS
.fullscreen-navlink.darkTheme {
  color: #fff; //Fallback var
  color: var(--dark-element1);
}
.fullscreen-navlink.lightTheme {
  color: #000; //Fallback var
  color: var(--light-element1);
}

.fullscreen-navlink.active {
  color: #796bae; //Fallback var
  color: var(--primary);
}

@media (min-width: 500px) {
  .fullscreen-navlink {
    .home-icon,
    .user-icon,
    .code-icon,
    .envelope-icon {
      left: 30vw;
    }
  }
}

@media (min-width: 650px) {
  .fullscreen-menu {
    display: none;
  }
}
