.main-nav {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 0 5vw; //IMPORTANT for Appearance
  font-size: 1.2rem;
  position: sticky;
  z-index: 100; //IMPORTANT FOR FULL MENU TO BE IN FRONT
}
// LIGHT & DARK THEME COLORS
.main-nav.darkTheme {
  background-color: #000;
  background-color: var(--dark-surface1);
}
.main-nav.lightTheme {
  background-color: #fff;
  background-color: var(--light-surface1);
}

.logo-link,
.menu-icon {
  align-self: center;
}

.nav-logo {
  height: 3.3em;
  width: 3.3em;
  display: block;
}

.navmenu {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  align-items: center;
  margin: 0;
  // display: none;
}

.navbar-ul {
  display: none;
}

//Container for theme toggle and menu nav icon
.nav-mobile-ul-container {
  display: flex;

  .nav-mobile-li-container {
    align-self: center;
  }
  .nav-mobile-li-container:first-of-type {
    margin-right: 1.25rem;
  }
  .nav-mobile-li-container:last-of-type {
    padding: 1rem 0;
    z-index: 12;
  }
}

/* 
--------------------
MENU ICON ANIMATION
--------------------
*/
.menu-icon {
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  .menu-line {
    height: 3px;
    width: 28px;
    margin: 0 0 0.3125rem 0;
    &:last-child {
      margin: 0;
    }
  }
  // LIGHT & DARK THEME COLORS
  .menu-line.darkTheme {
    background-color: var(--dark-element1);
  }
  .menu-line.lightTheme {
    background-color: var(--light-element1);
  }
  &.close {
    transform: rotate(180deg);
    .menu-line {
      &:nth-child(1) {
        transform: (rotate(45deg) translate(0.3125rem, 0.3125rem));
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(0.4375rem, -0.375rem);
      }
    }
  }
}

@media (min-width: 650px) {
  .nav-mobile-ul-container {
    display: none;
  }
  .menu-icon {
    display: none;
  }

  .navbar-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar-li {
      padding: 0 1rem;
    }
    .navbar-li-toggle {
      display: flex;
      padding: 0 1rem;
    }
    .navbar-link-tag {
      position: relative;
      text-decoration: none;
      font-weight: 700;
      padding-bottom: 0.5rem;
    }
    // LIGHT & DARK THEME COLORS
    .navbar-link-tag.darkTheme {
      color: #fff;
      color: var(--dark-element1);
    }
    .navbar-link-tag.lightTheme {
      color: #000;
      color: var(--light-element1);
    }

    .navbar-link-tag.darkTheme.active,
    .navbar-link-tag.lightTheme.active {
      color: #796bae;
      color: var(--primary);
    }

    .navbar-link-tag::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 0.125rem;
      transition: transform 0.5s;
      transform: scale(0, 1);
    }
    // LIGHT & DARK THEME COLORS
    .navbar-link-tag.darkTheme::after {
      color: #eee;
      background: var(--dark-element2);
    }
    .navbar-link-tag.lightTheme::after {
      color: #111;
      background: var(--light-element2);
    }

    .navbar-link-tag:hover {
      color: #796bae;
      color: var(--primary);
    }
    .navbar-link-tag:hover::after {
      transform: scale(1, 1);
      transform-origin: center;
    }
  }
}
